@charset "UTF-8";

body
{
	font-family: 'Lato', sans-serif;
	font-size: 16px;
	background-color: #F2F2F2;
	font-weight: 400;
}

textarea, input, select, option
{
	font-family: inherit;
}

a, body
{
	color: #3D3D3D;
}

a
{
	text-decoration: none;
}

img
{
	border: 0px;
}

textarea
{
	resize: none;
}

ul
{
	list-style-type: none;
}

.fleft
{
	float: left;
}

.fright
{
	float: right;
}

.fclear
{
	clear: both;
}

#container
{
	max-width: 500px;
	margin-left: auto;
	margin-right: auto;
}

header
{
	text-align: center;
	margin: 20px 0;
}

header h1
{
	font-size: 32px;
	letter-spacing: 1px;
}

.content
{
	width: 100%;
	background-color: #FFF;
	border-radius: 5px;
	margin: 10px 0;
	padding: 8px;
	box-sizing: border-box;
}

.content h2
{
	font-size: 20px;
	letter-spacing: 1px;
	margin-bottom: 6px;
}

.content p
{
	text-align: justify;
	margin-bottom: 5px;
	letter-spacing: 1px;
}

.content p:last-child
{
	margin-bottom: 0px;
}

#container #output
{
	display: none;
	padding: 20px 0;
}

#container #output a
{
	text-decoration: underline;
	font-size: 17px;
	line-height: 20px;
}

#pickFile {
	cursor: pointer;
}

#processing {
	display: none;
	margin-top: 10px;
}